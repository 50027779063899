import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Row from '../components/page-content-row'
import PageOverview from '../components/page-overview'
import { imgUrl } from '../util/helpers'
import RangeThumbDetail from '../components/range-thumb-detail'
import Image from '../components/page-image-new'
import { theme } from '../util/style'
import Hero from '../components/page-hero-gallery-video'
import BlogVideo from '../components/blog-page/PageBlog/BlogVideo'

export default class GalleryTemplate extends Component {
  render() {
    const { data } = this.props
    const {
      id,
      summary,
      title,
      description,
      thumb,
      shareImages,
      gallery,
      storey,
      stats,
      completion,
    } = data.galleryJson

    let image, ogImage, ogImages

    if (shareImages && shareImages.length >= 0) {
      ogImages = shareImages
    } else {
      ogImage = thumb
    }

    const renderTitle = (title) => {
      return title.split(/(\d+)/).map((part, index) =>
        /\d/.test(part) ? (
          <span key={index} className="number">
            {part}
          </span>
        ) : (
          part
        )
      )
    }

    const galleryContentAlt = []

    const firstImage = gallery[0]
    // const image0 = (
    //   <Box width={1} px={2}>
    //     <ImageWrap>
    //       <Image src={firstImage.image} />
    //     </ImageWrap>
    //   </Box>
    // )
    // const firstItem = (
    //   <Flex flexWrap="wrap" key="first-image">
    //     {image0}
    //   </Flex>
    // )
    // galleryContentAlt.push(firstItem)

    let i = 0
    while (i < gallery.length) {
      const key = `row-${i}`
      let increment = 2

      const currImage = gallery[i]

      if (currImage.video) {
        galleryContentAlt.push(
          <Box width={1} px={2} style={{ paddingBottom: '1em' }}>
            <BlogVideo
              data={{
                video: { playbackId: currImage.video, ratio: '16:9' },
                videoCover: {
                  file: {
                    url: `https://image.mux.com/${currImage.video}/thumbnail.jpg?time=0`,
                  },
                },
              }}
            />
          </Box>
        )
      } else if (currImage.fullWidth) {
        increment = 1
        galleryContentAlt.push(
          <Flex flexWrap="wrap" key={key}>
            <Box width={1} px={2}>
              <ImageWrap>
                <Image src={currImage.image} />
              </ImageWrap>
            </Box>
          </Flex>
        )
      } else if (currImage.halfWidthRight) {
        increment = 1
        galleryContentAlt.push(
          <Flex flexWrap="wrap" key={key}>
            <Box width={1} px={2}>
              <HalfRight>
                <Image src={currImage.image} />
              </HalfRight>
            </Box>
          </Flex>
        )
      } else if (currImage.halfWidthLeft) {
        increment = 1
        galleryContentAlt.push(
          <Flex flexWrap="wrap" key={key}>
            <Box width={1} px={2}>
              <HalfLeft>
                <Image src={currImage.image} />
              </HalfLeft>
            </Box>
          </Flex>
        )
      } else {
        const nextImage = gallery[i + 1]
        const nextImageEl = nextImage ? (
          <Box width={[1, 1 / 2]} px={2}>
            <ImageWrap>
              <Image src={nextImage.image} />
            </ImageWrap>
          </Box>
        ) : null
        galleryContentAlt.push(
          <Flex flexWrap="wrap" key={key}>
            <Box width={[1, 1 / 2]} px={2}>
              <ImageWrap>
                <Image src={currImage.image} />
              </ImageWrap>
            </Box>
            {nextImageEl}
          </Flex>
        )
      }

      i = i + increment
    }

    return (
      <>
        <SEO
          title={title}
          description={description}
          image={ogImage}
          images={ogImages}
          url={`gallery-${id}/`}
        />

        <Row>
          <Flex
            flexWrap="wrap"
            flexDirection={['column', 'row']}
            mt={['35px', '85px']}
          >
            <Box width={[1, 8 / 12]} px={2}>
              <Title>
                <b>{renderTitle(title)}</b>
                <br />
              </Title>
            </Box>
            <Box width={[1, 4 / 12]} px={2} mt={[2, 0]}>
              <RangeThumbDetail data={{ storey, stats, completion }} />
            </Box>
          </Flex>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 8 / 12]} px={2}>
              <PageOverview>{summary}</PageOverview>
            </Box>
          </Flex>
        </Row>

        <Row>{galleryContentAlt}</Row>
      </>
    )
  }
}

export const query = graphql`
  query ($slug: String!) {
    galleryJson(id: { eq: $slug }) {
      id
      title
      description
      stats {
        bed
        bath
        car
        price
        size
      }
      storey
      thumb
      shareImages
      summary
      completion
      gallery {
        fullWidth
        halfWidthRight
        image
        video
      }
    }
  }
`

const ImageWrap = styled.div`
  margin-bottom: 1em;
  img {
    display: block;
  }
`

const HalfRight = styled.div`
  margin-bottom: 1em;
  width: 50%;
  img {
    display: block;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const HalfLeft = styled.div`
  margin-bottom: 1em;
  width: 50%;
  float: right;
  img {
    display: block;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const Title = styled(Box)`
  font-size: 30px;
  font-family: ${theme.font.fontFamilyLight};
  line-height: 140%;

  b {
    font-weight: normal;
    font-family: ${theme.font.fontFamilyBold};
  }

  @media (max-width: 800px) {
    font-size: 22px;
  }

  .number {
    font-family: ${theme.font.fontFamilySerifThin};
  }
`
